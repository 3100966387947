<script>
const propName = "value", eventName = "input"
export default {
  name: "InputText",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    regex: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    fieldId: {
      type: String,
      required: true,
    },
    savedValue: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
  },
  data: function (){
    return {
      requiredRule: (v) => !!v || this.$t("pages.application.rules.required"),
      regexRule: (v) => RegExp(this.regex).test(v) || this.$t("pages.application.rules.format")
    }
  },
  computed: {
    rules() {
      let rulesArray = []
      if (this.required) rulesArray.push(this.requiredRule)
      if (this.regex) rulesArray.push(this.regexRule)
      return rulesArray
    },
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None")  {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    onEnter() {
      this.$emit("enter");
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <v-text-field
      v-model="model"
      :hint="hint"
      :placeholder="placeholder"
      :label="label"
      :rules="rules"
      :pattern="regex"
      outlined
      block
      x-large
      class="field"
      validate-on-blur
      @keydown.enter="onEnter"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.field::v-deep {
  border-radius: 10px;
  .v-btn__content {
    color: black;
  }
}
</style>